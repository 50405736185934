import React  from "react"
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { Section } from "../components/section";
import { Title2, TitleWrapper as DefaultTitleWrapper } from "../components/Headings";
import { ContentWrapper } from "../components/common";
import zoomSvg from '../images/svg/expand.svg';
import NewsGallery from "../components/NewsGallery";

const StyledButton = styled.button`
  font-family: 'Oswald', Georgia, sans-serif;
  font-size: 1.25rem;
  font-weight: 200;
  color: ${({theme}) => theme.colors.white};
  background-color: ${({theme}) => theme.colors.primary};
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.625rem 1.5rem;
  border: 0;

  &:hover {
    color: ${({theme}) => theme.colors.white};
    text-decoration: none;
  }

  /* @media (min-width: 1200px) {
    font-size: 1.75rem;
  } */
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
`;

const ZoomIcon = styled(zoomSvg)`
  width: 50px;
  height: 50px;
  fill: ${({theme}) => theme.colors.white};
`;

const ZoomIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 300ms ease;
  z-index: 11;
`;

const ZoomImageWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    transition: all 300ms ease;
    opacity: 0;
    z-index: 10 ;
  }

  &:hover:before {
    opacity: 1;
  }

  &:hover {
    ${ZoomIconWrapper} {
      opacity: 1;
    }
  }
`;


const ImageWrapper = styled.div`
  position: relative;
`;

const Title3 = styled(Title2)`
  font-size: 1.75rem;

  @media(min-width: 992px) {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
`;

const TitleWrapper = styled(DefaultTitleWrapper)`
  @media(min-width: 992px) {
    ${Title2} {
      margin-bottom: 20px
    }

    ${Title3} {
      margin-bottom: 30px;
    }
  }
`;

const LargeBullet = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background-color: ${({theme}) => theme.colors.primary};
  position: absolute;
  top: 0;
  transform: translateX(-50%);

  @media(min-width: 1200px) {
    width: 40px;
    height: 40px;
  }
`;

const SmallBullet = styled.div`
  display: none;
  width: 26px;
  height: 26px;
  border-radius: 100px;
  background-color: ${({theme}) => theme.colors.primary};
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);

  @media(min-width: 1200px) {
    width: 32px;
    height: 32px;
    top: 56px;
  }
`;

const BottomLargeBullet = styled(LargeBullet)`
  top: auto;
  bottom: 0;
`;

const Separator = styled.div`
  display: none;
  height: 100%;
  border-left: 2px dashed ${({theme}) => theme.colors.primary};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: visible;

  @media(min-width: 992px) {
    display: block;
  }
`;

const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-bottom: 60px;

  @media(min-width: 768px) {
    gap: 100px;
  }

  @media(min-width: 992px) {
    padding: 120px 0 90px;
    gap: 120px;
  }
`;

const ItemTitle = styled.h3`
  font-family: 'Oswald', Georgia, sans-serif;
  color: ${({theme}) => theme.colors.primary};
  font-size: 2.25rem;
  line-height: 3rem;
  text-transform: uppercase;
  text-align: center;

  @media(min-width: 992px) {
    text-align: left;
  }

  @media(min-width: 1200px) {
    font-size: 3rem;
    line-height: 3.75rem;
  }
`;

const ItemSubtitle = styled.p`
  font-family: 'Oswald', Georgia, sans-serif;
  color: ${({theme}) => theme.colors.black};
  font-size: 1.75rem;
  line-height: 2.375rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;

  @media(min-width: 992px) {
    text-align: left;
  }

  @media(min-width: 1200px) {
    font-size: 2.25rem;
    line-height: 3rem;
  }
`;

const ItemText = styled.p`
  color: ${({theme}) => theme.colors.black};
  font-size: 1.125;
  line-height: 1.75rem;
  margin-bottom: 0;
  text-align: center;

  @media(min-width: 992px) {
    text-align: left;
  }
`;

const ItemContent = styled.div`
  @media(min-width: 992px) {
    padding: 40px 0;
  }
`;

const MediaContent = styled.div`
`;

const ItemGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;
  column-gap: 160px;

  @media(min-width: 992px) {
    grid-template-columns: 1fr 1fr;

    &.even {
      ${ItemContent} {
        order: 2;
      }

      ${MediaContent} {
        order: 1;
      }
    }

    ${SmallBullet} {
      display: block;
    }
  }

  @media(min-width: 1200px) {
    column-gap: 200px;
  }

  @media(min-width: 1400px) {
    column-gap: 420px;
  }
`;

const Note = styled.div`
  text-align: center;

  p {
    display: inline;
    color: ${({theme}) => theme.colors.primary};
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 0;

    @media(min-width: 992px) {
      display: block;
    }

    @media(min-width: 1400px) {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  padding-top: 56.25%; // Player ratio: 100 / (1280 / 720)

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

function NewsPage () {

  return (
    <Layout>
      <Seo title="Aktuality" />
      <Section>
        <ContentWrapper>
          <TitleWrapper>
            <Title2>Podívejte se na vývoj stavby</Title2>
            <Title3 as="p">Jak to celé začalo</Title3>
          </TitleWrapper>

          <List>
            <Separator>
              <LargeBullet />
              <BottomLargeBullet />
            </Separator>
            <ItemGrid>
              <SmallBullet />
              <ItemContent>
                <ItemTitle>Únor 2024</ItemTitle>
                <ItemSubtitle>Stavba zahájena</ItemSubtitle>
                <ItemText>
                  Máme skvělé zprávy, stavba Rezidence Třešňovka je oficiálně zahájena. Sledujte naše aktuality pro další informace a&nbsp;vývoj stavby.
                </ItemText>
              </ItemContent>
              <MediaContent>
                <ImageWrapper>
                  <StaticImage src="../images/aktuality/aktualita-unor-2024.jpg" placeholder="blurred" layout="fullWidth" alt="Foto" />
                </ImageWrapper>
              </MediaContent>
            </ItemGrid>
            <ItemGrid className="even">
              <SmallBullet />
              <ItemContent>
                <ItemTitle>Březen / Duben 2024</ItemTitle>
                <ItemSubtitle>Betonové základy</ItemSubtitle>
                <ItemText>
                  Betonové základy jsou již vytvořeny a&nbsp;nyní přecházíme k&nbsp;vyzdívání prvního podzemního podlaží, kde najdete sklepy a&nbsp;nebytové jednotky.
                </ItemText>
              </ItemContent>
              <MediaContent>
                <VideoWrapper>
                  <ReactPlayer
                    className="react-player"
                    url="https://vimeo.com/937782843"
                    width="100%"
                    height="100%"
                    controls={true}
                    />
                </VideoWrapper>
                <NewsGallery folder="brezen-2024" data={['01', '02', '03', '04']}>
                  <BtnWrapper>
                    <StyledButton>Foto</StyledButton>
                  </BtnWrapper>
                </NewsGallery>
              </MediaContent>
            </ItemGrid>
            <ItemGrid>
              <SmallBullet />
              <ItemContent>
                <ItemTitle>Květen 2024</ItemTitle>
                <ItemSubtitle>Dokončení 1.&nbsp;PP</ItemSubtitle>
                <ItemText>
                  Hlásíme dokončení prvního podzemního podlaží včetně sklepů a&nbsp;nebytových jednotek, aktuálně vyzdíváme byty v&nbsp;1.&nbsp;NP.
                </ItemText>
              </ItemContent>
              <MediaContent>
                <VideoWrapper>
                  <ReactPlayer
                    className="react-player"
                    url="https://vimeo.com/946106111"
                    width="100%"
                    height="100%"
                    controls={true}
                    />
                </VideoWrapper>
                <NewsGallery folder="kveten-2024" data={['01', '02', '03', '04', '05']}>
                  <BtnWrapper>
                    <StyledButton>Foto</StyledButton>
                  </BtnWrapper>
                  {/* <ZoomImageWrapper>
                    <StaticImage src="../images/aktuality/aktualita-kveten-2024.jpg" placeholder="blurred" layout="fullWidth" alt="Foto" />
                    <ZoomIconWrapper>
                      <ZoomIcon alt="Ikona" />
                    </ZoomIconWrapper>
                  </ZoomImageWrapper> */}
                </NewsGallery>
              </MediaContent>
            </ItemGrid>
            <ItemGrid className="even">
              <SmallBullet />
              <ItemContent>
                <ItemTitle>Červenec 2024</ItemTitle>
                <ItemSubtitle>Stavba roste do výšky</ItemSubtitle>
                <ItemText>
                  Stavba nám doslova roste před očima, aktuálně máme postavené 3.&nbsp;NP a&nbsp;blížíme se k&nbsp;poslednímu - 4.&nbsp;NP.
                </ItemText>
              </ItemContent>
              <MediaContent>
                <NewsGallery folder="cervenec-2024" data={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17']}>
                  <ZoomImageWrapper>
                    <StaticImage src="../images/aktuality/aktualita-cervenec-2024.jpg" placeholder="blurred" layout="fullWidth" alt="Foto" />
                    <ZoomIconWrapper>
                      <ZoomIcon alt="Ikona" />
                    </ZoomIconWrapper>
                  </ZoomImageWrapper>
                </NewsGallery>
              </MediaContent>
            </ItemGrid>

            <ItemGrid>
              <SmallBullet />
              <ItemContent>
                <ItemTitle>Srpen 2024</ItemTitle>
                <ItemSubtitle>Dokončení 4.&nbsp;NP</ItemSubtitle>
                <ItemText>
                  Stavba běží podle plánu. Hrubá stavba dostává finální podobu a&nbsp;začínáme pracovat na vnitřních pracích.
                </ItemText>
              </ItemContent>
              <MediaContent>
                <NewsGallery folder="srpen-2024" data={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11']}>
                  <ZoomImageWrapper>
                    <StaticImage src="../images/aktuality/aktualita-srpen-2024.jpg" placeholder="blurred" layout="fullWidth" alt="Foto" />
                    <ZoomIconWrapper>
                      <ZoomIcon alt="Ikona" />
                    </ZoomIconWrapper>
                  </ZoomImageWrapper>
                </NewsGallery>
              </MediaContent>
            </ItemGrid>
          </List>

          <Note>
            <p>Na Třešňovce se teprve staví, a&nbsp;tak prohlídky ještě nejsou možné. </p>
            <p>My jsme tu ale proto, abychom vám pomohli najít váš ideální domov. </p>
            <p>Pokud vás Třešňovka zaujala a&nbsp;máte jakékoliv dotazy, ozvěte se nám. </p>
          </Note>

        </ContentWrapper>
      </Section>
    </Layout>
  );
}

export default NewsPage;
